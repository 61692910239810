<template>
  <main class="mx-auto max-w-screen-xl px-4 py-8 lg:p-12">
    <h1 class="font-bold text-xl text-center">Online Timer, Stopwatch, and Countdown Tools with Alarm on Datetime.live</h1>
    <div class="mt-8">
      <ToolList :tool-list="toolStore.tools"/>
    </div>
  </main>
</template>

<script setup lang="ts">
import { useToolStore } from "$/stores/tools";
import { useHead } from '@unhead/vue'
import ToolList from "./ToolList.vue";

const toolStore = useToolStore()

useHead({
  title: 'DateTime.live - Free Online Timer, Stopwatch, and Time Management Tools',
  meta: [
    {
      name: "description",
      content: "Manage your time effectively with DateTime.live. Use our free online timer, stopwatch, countdown, and other time management tools for work, study, cooking, and more.",
    },
  ],
  link: [
    {
      rel: 'canonical',
      href: `${import.meta.env.VITE_BASE_URL}`
    }
  ]
})
</script>
