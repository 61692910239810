<template>
  <main v-if="ready" class="w-full h-full px-4 py-4 lg:p-12" :class="{'alarm-bg': isAlarm}">
    <ToolTopSection :tool="toolStore.tool"/>

    <section>
      <div class="mt-8 display-time">{{ displayTime }}</div>

      <div class="mt-8 flex justify-center space-x-8">
        <button :disabled="isRunning" data-modal-target="timer-setting-modal" data-modal-toggle="timer-setting-modal" class="btn btn--circle btn--gray btn--lg">
          <i class="fa-solid fa-gear"></i>
          <span>Setting</span>
        </button>
        <button v-if="isRunning" class="btn btn--circle btn--yellow btn--lg" @click="stop">
          <i class="fa-solid fa-pause"></i>
          <span>Stop</span>
          <span v-if="isAlarm" class="ring"></span>
        </button>
        <button v-else class="btn btn--circle btn--green btn--lg" @click="start" :disabled="secondsRemaining === 0">
          <i class="fa-solid fa-play"></i>
          <span>Start</span>
        </button>
        <button class="btn btn--circle btn--red btn--lg" @click="reset">
          <i class="fa-solid fa-power-off"></i>
          <span>Reset</span>
        </button>
      </div>
    </section>

    <!-- Modal -->
    <div id="timer-setting-modal" tabindex="-1" aria-hidden="true" class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
      <div class="relative p-4 w-full max-w-xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              Timer setting
            </h3>
            <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="timer-setting-modal">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span class="sr-only">Close</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-4 md:p-5">
            <div class="flex space-x-2">
              <div class="flex flex-col space-y-1 w-full max-w-[120px]">
                <label class="input-label">Hours</label>
                <input v-model="toolStore.options.timer.hours" type="number" min="0" max="23" class="input-field" placeholder="0"/>
              </div>
              <div class="flex flex-col space-y-1 w-full max-w-[120px]">
                <label class="input-label">Minutes</label>
                <input v-model="toolStore.options.timer.minutes" type="number" min="0" max="59" class="input-field" placeholder="0"/>
              </div>
              <div class="flex flex-col space-y-1 w-full max-w-[120px]">
                <label class="input-label">Seconds</label>
                <input v-model="toolStore.options.timer.seconds" type="number" min="0" max="59" class="input-field" placeholder="0"/>
              </div>
            </div>
            <div class="mt-4 flex flex-wrap space-x-4 items-center">
              <label class="flex items-center space-x-1 cursor-pointer">
                <input v-model="toolStore.options.timer.tickingSound" type="checkbox" value="" class="sr-only peer">
                <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span class="input-label">Ticking sound</span>
              </label>
              <audio class="max-w-[250px]" controls :src="clockTicking1URL"></audio>
            </div>
            <div class="mt-4">
              <label class="input-label">Alarm sound</label>
              <table class="w-full">
                <tbody>
                <tr v-for="sound in alarmSounds" :key="sound.value">
                  <td>
                    <label class="flex space-x-1 items-center">
                      <input
                        v-model="toolStore.options.timer.alarmSound"
                        type="radio"
                        :value="sound.value"
                      />
                      <span>{{ sound.label }}</span>
                    </label>
                  </td>
                  <td>
                    <audio class="max-w-[250px]" controls :src="sound.url" />
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="flex items-center justify-end p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button data-modal-hide="timer-setting-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Close</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End modal -->
  </main>
</template>

<script setup lang="ts">
import {ref, computed, watch, watchEffect, nextTick} from "vue";
import { useToolSetup} from "$/composables/useToolSetup";
import { useFlowbite } from "$/composables/useFlowbite";
import clockTicking1URL from '@/audios/clock_ticking_1.mp3'
import alarmSound1URL from '@/audios/alarm_sound_1.mp3'
import alarmSound2URL from '@/audios/alarm_sound_2.mp3'
import alarmMusic1URL from '@/audios/alarm_music_1.mp3'
import alarmMusic2URL from '@/audios/alarm_music_2.mp3'
import ToolTopSection from "&/ToolTopSection.vue";

const { toolStore, ready } = useToolSetup()

const clockTickingSound = new Audio(clockTicking1URL)
const intervalId = ref<number | null>(null);
const isRunning = ref(false)
const isAlarm = ref(false)
const secondsRemaining = ref(0)
const displayTime = computed(() => {
  const hours = Math.floor(secondsRemaining.value / 60 / 60)
  const minutes = Math.floor(secondsRemaining.value / 60 % 60);
  const seconds = secondsRemaining.value % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
});

const alarmSound = new Audio(alarmSound1URL)
const alarmSounds = [
  { value: 'alarm_sound_1', label: 'Sound 1', url: alarmSound1URL },
  { value: 'alarm_sound_2', label: 'Sound 2', url: alarmSound2URL },
  { value: 'alarm_music_1', label: 'Music 1', url: alarmMusic1URL },
  { value: 'alarm_music_2', label: 'Music 2', url: alarmMusic2URL },
];

watchEffect(async () => {
  if (ready.value) {
    await nextTick()
    useFlowbite(['modals']);
  }
});

watch(
  () => [toolStore.options.timer.hours, toolStore.options.timer.minutes, toolStore.options.timer.seconds],
  () => {
    calcSecondsRemaining()
  }, { immediate: true }
);

watch(
  () => toolStore.options.timer.alarmSound,
  (newValue) => {
    switch (newValue) {
      case 'alarm_sound_1':
        alarmSound.src = alarmSound1URL;
        break;
      case 'alarm_sound_2':
        alarmSound.src = alarmSound2URL;
        break;
      case 'alarm_music_1':
        alarmSound.src = alarmMusic1URL;
        break;
      case 'alarm_music_2':
        alarmSound.src = alarmMusic2URL;
        break;
      default:
        alarmSound.src = alarmSound1URL;
    }
  }
);

function calcSecondsRemaining() {
  secondsRemaining.value =
    toolStore.options.timer.hours * 3600 +
    toolStore.options.timer.minutes * 60 +
    toolStore.options.timer.seconds;
}

function start() {
  secondsRemaining.value =
    toolStore.options.timer.hours * 3600 +
    toolStore.options.timer.minutes * 60 +
    toolStore.options.timer.seconds;

  if (secondsRemaining.value > 0) {
    isRunning.value = true;
    intervalId.value = setInterval(() => {
      if (toolStore.options.timer.tickingSound) clockTickingSound.play()
      if (secondsRemaining.value > 0) {
        secondsRemaining.value--;
      } else {
        startAlarm()
      }
    }, 1000);
  }
}

function stop() {
  if (intervalId.value) {
    clearInterval(intervalId.value);
    intervalId.value = null;
    isRunning.value = false;
    isAlarm.value = false;
    stopSounds()
    calcSecondsRemaining()
  }
}

function reset() {
  stop();
  secondsRemaining.value = 0;
  toolStore.options.timer.hours = 0;
  toolStore.options.timer.minutes = 0;
  toolStore.options.timer.seconds = 0;
}

function startAlarm() {
  isAlarm.value = true;
  alarmSound.play()
  clockTickingSound.pause()
  clockTickingSound.currentTime = 0
}

function stopSounds() {
  alarmSound.pause()
  alarmSound.currentTime = 0
  clockTickingSound.pause()
  clockTickingSound.currentTime = 0
}
</script>

<style scoped lang="scss">
.alarm-bg {
  background: rgb(238,174,202);
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
}
</style>
