<template>
  <div v-if="!store.loading" class="grid">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { useStore } from "$/stores";
import { useToolStore } from "$/stores/tools";

const store = useStore()
const toolStore = useToolStore()

onMounted(async () => {
  await toolStore.fetchTools()
})
</script>
