<template>
  <main v-if="ready" class="w-full h-full px-4 py-4 lg:p-12">
    <ToolTopSection :tool="toolStore.tool"/>

    <section>
      <div class="mt-8 display-time">{{ displayTime }}</div>

      <div class="mt-8 flex justify-center space-x-8">
        <button v-if="isRunning" class="btn btn--circle btn--yellow btn--lg" @click="pause">
          <i class="fa-solid fa-pause"></i>
          <span>Pause</span>
        </button>
        <button v-else class="btn btn--circle btn--green btn--lg" @click="start">
          <i class="fa-solid fa-play"></i>
          <span>Start</span>
        </button>
        <button class="btn btn--circle btn--gray btn--lg" @click="split" :disabled="elapsedTime === 0">
          <i class="fa-solid fa-scissors"></i>
          <span>Split</span>
        </button>
        <button class="btn btn--circle btn--red btn--lg" @click="reset">
          <i class="fa-solid fa-power-off"></i>
          <span>Reset</span>
        </button>
      </div>
    </section>

    <section class="mt-8">
      <div class="flex space-x-4 justify-center">
        <label class="flex items-center space-x-1 cursor-pointer">
          <input v-model="toolStore.options.stopwatch.tickingSound" type="checkbox" value="" class="sr-only peer">
          <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="input-label">Ticking sound</span>
        </label>
        <label class="flex items-center space-x-1 cursor-pointer">
          <input v-model="toolStore.options.stopwatch.splitSound" type="checkbox" value="" class="sr-only peer">
          <div class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span class="input-label">Split sound</span>
        </label>
      </div>
    </section>

    <section v-if="splits.length > 0" class="mt-8">
      <table class="table table--sm table--stripped table--bordered mx-auto">
        <thead>
          <tr>
            <th>#</th>
            <th>Split time</th>
            <th>Total time</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(split, index) in splits" :key="index">
            <td class="text-right">{{ splits.length - index }}</td>
            <td class="px-4">{{ split.splitTime }}</td>
            <td class="px-4">{{ split.totalTime }}</td>
          </tr>
        </tbody>
      </table>
    </section>
    <!-- End modal -->
  </main>
</template>

<script setup lang="ts">
import {ref, computed, watch, watchEffect, nextTick} from "vue";
import { useToolSetup} from "$/composables/useToolSetup";
import { useFlowbite } from "$/composables/useFlowbite";
import ToolTopSection from "&/ToolTopSection.vue";
import splitSoundURL from "@/audios/beep_short.mp3";
import clockTicking1URL from '@/audios/clock_ticking_1.mp3'

const { toolStore, ready } = useToolSetup()
const splits = ref<{ splitTime: string, totalTime: string }[]>([])
const isRunning = ref(false)
let intervalId: number | null = null;
const elapsedTime = ref(0)
const lastSplitTime = ref(0)
const displayTime = computed(() => {
  const milliseconds = Math.floor((elapsedTime.value % 1000) / 10)
  const hours = Math.floor(elapsedTime.value / 1000 / 60 / 60)
  const minutes = Math.floor(elapsedTime.value / 1000 / 60 % 60);
  const seconds = Math.floor(elapsedTime.value / 1000 % 60);
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${String(milliseconds).padStart(2, '0')}`;
});

const splitSound = new Audio(splitSoundURL)
const tickingSound = new Audio(clockTicking1URL)

watchEffect(async () => {
  if (ready.value) {
    await nextTick()
    useFlowbite(['modals']);
  }
});

watch(
  () => toolStore.options.stopwatch.tickingSound,
  (newValue) => {
    if (!newValue) {
      tickingSound.pause();
      tickingSound.currentTime = 0;
    }
  }
);

function start() {
  isRunning.value = true
  intervalId = setInterval(() => {
    elapsedTime.value += 10;
    if (toolStore.options.stopwatch.tickingSound && elapsedTime.value % 1000 === 0) {
      tickingSound.play()
    }
  }, 10);
}

function pause() {
  clearInterval(intervalId!);
  intervalId = null;
  isRunning.value = false;
  tickingSound.pause()
  tickingSound.currentTime = 0
}

function reset() {
  pause();
  elapsedTime.value = 0;
  lastSplitTime.value = 0;
  splits.value = [];
}

function split() {
  const milliSeconds = elapsedTime.value - lastSplitTime.value;
  const splitMilliSeconds = Math.floor((milliSeconds % 1000) / 10);
  const splitSeconds = Math.floor((milliSeconds / 1000) % 60);
  const splitMinutes = Math.floor((milliSeconds / 60000) % 60);
  const splitHours = Math.floor((milliSeconds / 3600000) % 24);
  const splitTime = `${String(splitHours).padStart(2, '0')}:${String(splitMinutes).padStart(2, '0')}:${String(splitSeconds).padStart(2, '0')}.${String(splitMilliSeconds % 1000).padStart(2, '0')}`;

  const totalTime = displayTime.value;

  splits.value.unshift({ splitTime, totalTime });

  lastSplitTime.value = elapsedTime.value;

  if (toolStore.options.stopwatch.splitSound) {
    splitSound.play()
  }
}
</script>
