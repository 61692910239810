<template>
  <header class="bg-slate-200 shadow">
    <div class="px-4 py-2 mx-auto max-w-screen-xl">
      <router-link to="/" class="flex space-x-1 items-center">
        <img src="@/images/logo_256.png" alt="DateTime.live - Countdown, Timer, Stopwatch and more." class="w-10"/>
        <div>
          <span class="font-bold text-2xl">DateTime</span>
          <span class="text-xl">.live</span>
        </div>
      </router-link>
    </div>
  </header>
</template>

<script setup lang="ts">
</script>
