import axios from 'axios'

const apiCall = axios.create({
    baseURL: '/api/v1',
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
    }
})

apiCall.interceptors.request.use(
    (config) => {
        // const token = localStorage.getItem('token');
        // if (token) {
        //   config.headers.Authorization = `Bearer ${token}`;
        // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiCall.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error);
    }
);

export default apiCall;
