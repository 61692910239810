import { ref, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '$/stores'
import { useToolStore } from '$/stores/tools'
import { useHead } from '@unhead/vue'
import { getCurrentFeatureSlug } from "$/utils";

export function useToolSetup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const toolStore = useToolStore()
    const ready = ref(false)

    onMounted(() => {
        toolStore.fetchTool(getCurrentFeatureSlug())
            .finally(() => {
                ready.value = true
                useHead({
                    title: toolStore.tool.meta_title,
                    meta: [
                        {
                            name: "description",
                            content: toolStore.tool.meta_description,
                        },
                    ],
                    link: [
                        {
                            rel: 'canonical',
                            href: `${import.meta.env.VITE_BASE_URL}/${toolStore.tool.slug}`
                        }
                    ]
                })
            })
    })

    return { route, router, store, toolStore, useHead, ready }
}
