import { createRouter, createWebHistory } from 'vue-router'

import ToolIndex from '&/ToolIndex.vue'
import ToolTimer from '&/tools/ToolTimer.vue'
import ToolStopwatch from '&/tools/ToolStopwatch.vue'

const routes = [
    { path: '/', name: 'ToolIndex', component: ToolIndex },
    { path: '/timer', name: 'ToolTimer', component: ToolTimer },
    { path: '/stopwatch', name: 'ToolStopwatch', component: ToolStopwatch },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.afterEach(() => {
    window.scrollTo(0, 0)
})

export default router
