import { defineStore } from "pinia";
import apiCall from '$/plugins/axios'
import { useStore } from "$/stores";

const store = useStore

export const useToolStore = defineStore('tool', {
    state: () => ({
        loading: true,
        tools: [],
        tool: {},
        options: {
            'timer': {
                hours: 0,
                minutes: 0,
                seconds: 0,
                tickingSound: false,
                alarmSound: 'alarm_sound_1',
            },
            'stopwatch': {
                tickingSound: false,
                splitSound: false,
            },
        },
    }),

    actions: {
        async fetchTools() {
            try {
                const res = await apiCall.get('/tools')
                this.tools = res.data
            } catch(err) {
                console.error('Failed to fetch tools:', err.message || err)
            } finally {
                store.loading = false
            }
        },

        async fetchTool(slug) {
            try {
                const res = await apiCall.get(`/tools/${slug}`)
                this.tool = res.data
            } catch(err) {
                console.error('Failed to fetch tools', err.mesage || err)
            } finally {
                store.loading = false
            }
        },
    }
})
